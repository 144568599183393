export enum ChannelLogos {
  SRC_CT = '/static/channels/Ceska_televize_logo.svg',
  SRC_CT_24 = '/static/channels/ct24.svg',
  SRC_CT_24_DARK = '/static/channels/ct24_onDark.svg',
  SRC_CT_ART = '/static/channels/ctart.svg',
  SRC_CT_D = '/static/channels/ctd.svg',
  SRC_CT_DARK = '/static/channels/Ceska_televize_logo_onDark.svg',
  SRC_CT_EDU = '/static/channels/edu.svg',
  SRC_CT_EDU_DARK = '/static/channels/edu_onDark.svg',
  SRC_CT_IVYSILANI = '/static/channels/ivysilani.svg',
  SRC_CT_IVYSILANI_DARK = '/static/channels/ivysilani_onDark.svg',
  SRC_CT_MOBILE = '/static/logos/CeskaTelevize.svg',
  SRC_CT_MOBILE_DARK = '/static/logos/CeskaTelevizeDark.svg',
  SRC_CT_SPORT = '/static/channels/ctsport.svg',
  SRC_CT_SPORT_DARK = '/static/channels/ctsport_onDark.svg',
}
