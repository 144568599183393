import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useContextWithProvider } from '@czechtv/components';
import { PlayersControllerContext } from '../components/PlayersControllerContext/PlayersControllerContext';
import {
  getPlayerError,
  isRecoverableError,
  PlayerNormalizedError,
} from '../components/Error/playerError';
import { usePlayerAnalytics } from '../Providers/Analytics/useAnalyticsContext';
import PlayerErrorOverlay from '../components/Error/Overlay/Overlay';
import { usePlayerSetup } from '../Providers/Setup/usePlayerSetup';
import { getErrorMessage } from '../components/Error/errorMessage';
import useHbbtvConnect from '../utils/useHbbtvConnect';
import { log } from '../utils/logger';
import {
  HbbtvConnect,
  HbbtvDevice,
  ChromecastDevice,
  LiveMode,
  MenuPopupType,
  TIMESHIFT_GAP_DURATION,
  LIVE_STREAM_DURATION,
  NO_GAP_LIVE_TIMESHIFT_BUFFER,
  VideoSubtitles,
  VideoIndex,
} from '../constants';
import { PlaylistOptions } from '../utils/playlists/constants';
import { useChromecast, ChromecastState } from '../utils/chromecast/useChromecast';
import { PlayerAudioTrack } from '../utils/types';
import { CaptionColorVariant, CaptionFontSize } from '../utils/closedCaptions';
import { OverlayIconType } from './Overlay/VOD/Icon/typeDefs';
import { getLocalStoragePlayerSettings, updateLocalStoragePlayerSettings } from './playerSettings';

export interface PlayerContextValues {
  activeChromecastDevice: ChromecastDevice | null;
  ageRestriction?: string | null;
  aspectRatio: string;
  bonusId?: string;
  captionColorVariant: CaptionColorVariant;
  captionFontSize: CaptionFontSize;
  chromecastState: ChromecastState;
  chromecastStreamingActiveDevice?: ChromecastDevice | null;
  cropEnd?: number;
  cropStart?: number;
  disableAirplay?: boolean;
  disableChromecast?: boolean;
  encoder?: string;
  encoderTimeDiff?: number;
  endTimestamp?: number;
  externalId?: string;
  forcedAudioOnly: boolean;
  getChromecastCurrentTime: () => number;
  getChromecastVolume: () => number;
  hbbtv: HbbtvConnect;
  hbbtvStreamingActiveDevice: HbbtvDevice | null;
  idec?: string | null;
  indexId?: string;
  indexListVisible: boolean | undefined;
  indexes: VideoIndex[] | undefined;
  isAudioOnly?: boolean;
  isChromecastApiAvailable: boolean;
  isChromecastSession: boolean;
  isNewPlaylist: boolean;
  keyboardControlsHideState: boolean;
  licenseInfo?: string;
  liveActivated: boolean;
  liveCaptionsOn: boolean;
  liveMode?: LiveMode;
  liveStreamDuration: number;
  mediaMetaId?: string;
  menuPopupVisible: MenuPopupType | null;
  noGapTimeshift?: boolean;
  onChromecastChangeAudioTrack: (audioTrack: PlayerAudioTrack | null) => void;
  onChromecastChangeSubtitles: (subtitles: VideoSubtitles | null) => void;
  onChromecastMute: () => void;
  onChromecastPlayPause: () => void;
  onChromecastSeek: (value: number) => void;
  onChromecastSeekBy: (value: number) => void;
  onChromecastVolumeChange: (value: number) => void;
  overlay: { icon: OverlayIconType | null; updated: number };
  parentUrl?: string;
  playbackRate: number;
  playerError: PlayerNormalizedError | null;
  playlistOptions: PlaylistOptions;
  preventHideControls: boolean;
  previewTrackBaseUrl: string;
  previewTrackStartOffset?: number;
  selectedChromecastAudioTrack: PlayerAudioTrack | null;
  selectedChromecastSubtitles: VideoSubtitles | null;
  setActiveIndexCheckCallback: (cb: () => void) => void;
  setAgeRestriction: Dispatch<SetStateAction<string | null | undefined>>;
  setAspectRatio: Dispatch<SetStateAction<string>>;
  setCaptionColorVariant: Dispatch<SetStateAction<CaptionColorVariant>>;
  setCaptionFontSize: Dispatch<SetStateAction<CaptionFontSize>>;
  setCurrentStreamUrl: Dispatch<SetStateAction<string | null>>;
  setForcedAudioOnly: Dispatch<SetStateAction<boolean>>;
  setHbbtvStreamingActiveDevice: Dispatch<SetStateAction<HbbtvDevice | null>>;
  setIndexListVisible: Dispatch<SetStateAction<boolean | undefined>>;
  setIndexes: Dispatch<SetStateAction<VideoIndex[] | undefined>>;
  setKeyboardControlsHideState: Dispatch<SetStateAction<boolean>>;
  setLiveActivated: Dispatch<SetStateAction<boolean>>;
  setLiveCaptionsOn: Dispatch<SetStateAction<boolean>>;
  setMenuPopupVisible: Dispatch<SetStateAction<MenuPopupType | null>>;
  setOverlayIcon: (icon: OverlayIconType | null) => void;
  setPlaybackRate: Dispatch<SetStateAction<number>>;
  // na contextu si poradíme s jakýkoliv errorem
  // zalogujeme a vyparsujeme uživatelsky čitelnou chybu
  setPlayerError: (error: unknown) => void;
  setPreventHideControls: Dispatch<SetStateAction<boolean>>;
  setShowRecommendedVideos: Dispatch<SetStateAction<boolean | undefined>>;
  setShowTitle: Dispatch<SetStateAction<string | null | undefined>>;
  setStartOffset: Dispatch<SetStateAction<number>>;
  setTimeShift: Dispatch<SetStateAction<number | null>>;
  setTimeUpdateCallback: (cb: (value: number) => void) => void;
  setVideoStartsAt: Dispatch<SetStateAction<Date | null>>;
  setVideoTitle: Dispatch<SetStateAction<string | null | undefined>>;
  setVolumeUpdateCallback: (cb: (value: number) => void) => void;
  showId?: string;
  showRecommendedVideos: boolean | undefined;
  showTitle?: string | null;
  startOffset: number;
  startTimestamp?: number;
  timeShift: number | null;
  timeshiftGapDuration: number;
  toggleChromecast: () => void;
  versionId?: string;
  videoStartsAt?: Date | null;
  videoTitle?: string | null;
}

export interface PlayerContextProviderProps {
  bonusId?: string;
  children: React.ReactNode;
  cropEnd?: number;
  cropStart?: number;
  defaultAgeRestriction?: string | null;
  defaultIndexes?: VideoIndex[];
  defaultShowTitle?: string | null;
  defaultTimeShift?: number | null;
  defaultVideoStartsAt?: Date | null;
  defaultVideoTitle?: string | null;
  disableAirplay?: boolean;
  disableChromecast?: boolean;
  encoder?: string;
  encoderDiff?: number;
  endTimestamp?: number;
  externalId?: string;
  idec?: string | null;
  indexId?: string;
  isAudioOnly?: boolean;
  isNewPlaylist: boolean;
  licenseInfo?: string;
  liveMode?: LiveMode;
  mainContentId: string;
  mediaMetaId?: string;
  noGapTimeshift?: boolean;
  parentUrl?: string;
  playlistOptions: PlaylistOptions;
  previewImage?: string;
  previewTrackBaseUrl: string;
  previewTrackStartOffset?: number;
  showId?: string;
  startTimestamp?: number;
  subtitles?: VideoSubtitles[];
  versionId?: string;
}

export const PlayerContext = createContext<PlayerContextValues | undefined>(undefined);

export const usePlayerContext = () => useContextWithProvider(PlayerContext);

export const PlayerContextProvider = ({
  bonusId,
  children,
  cropEnd,
  cropStart,
  defaultAgeRestriction,
  defaultIndexes,
  defaultShowTitle,
  defaultTimeShift = null,
  defaultVideoStartsAt = null,
  defaultVideoTitle,
  disableAirplay,
  disableChromecast,
  encoder,
  encoderDiff: defaultEncoderDiff,
  endTimestamp,
  externalId,
  idec,
  indexId,
  previewTrackStartOffset,
  isAudioOnly,
  isNewPlaylist,
  licenseInfo,
  liveMode,
  mainContentId,
  mediaMetaId,
  noGapTimeshift,
  parentUrl,
  playlistOptions,
  previewImage,
  previewTrackBaseUrl,
  showId,
  startTimestamp,
  subtitles,
  versionId,
}: PlayerContextProviderProps) => {
  const [currentStreamUrl, setCurrentStreamUrl] = useState<string | null>(null);
  const playersControllerContext = useContext(PlayersControllerContext);
  const { client, onError } = usePlayerAnalytics();
  const { reload, playerVariant } = usePlayerSetup();
  const [ageRestriction, setAgeRestriction] = useState(defaultAgeRestriction);
  const [indexes, setIndexes] = useState(defaultIndexes);
  const [videoTitle, setVideoTitle] = useState(defaultVideoTitle);
  const [videoStartsAt, setVideoStartsAt] = useState(defaultVideoStartsAt);
  const [showTitle, setShowTitle] = useState(defaultShowTitle);
  const [hbbtvStreamingActiveDevice, setHbbtvStreamingActiveDevice] = useState<HbbtvDevice | null>(
    null
  );
  const [showRecommendedVideos, setShowRecommendedVideos] = useState<boolean | undefined>(
    undefined
  );
  const { playerClient } = usePlayerSetup();
  const [playerError, setPlayerError] = useState<PlayerNormalizedError | null>(null);
  const [indexListVisible, setIndexListVisible] = useState<boolean | undefined>(undefined);
  const [liveActivated, setLiveActivated] = useState(true);
  const [overlay, setOverlayIcon] = useReducer(
    (_state: { icon: OverlayIconType | null; updated: number }, icon: OverlayIconType | null) => {
      return { updated: Date.now(), icon };
    },
    { updated: 0, icon: null }
  );
  const [preventHideControls, setPreventHideControls] = useState(false);
  const [menuPopupVisible, setMenuPopupVisible] = useState<MenuPopupType | null>(null);
  const [timeShift, setTimeShift] = useState(defaultTimeShift);
  const [encoderTimeDiff, setEncoderTimeDiff] = useState(defaultEncoderDiff);
  const [startOffset, setStartOffset] = useState(0);
  const [aspectRatio, setAspectRatio] = useState('');
  const [keyboardControlsHideState, setKeyboardControlsHideState] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [forcedAudioOnly, setForcedAudioOnly] = useState(
    !!playlistOptions.forceAudioOnly?.audioOnly
  );

  const [captionColorVariant, setCaptionColorVariant] = useState<CaptionColorVariant>(
    getLocalStoragePlayerSettings()?.additionalConfig?.captionColorVariant ||
      CaptionColorVariant.default
  );
  const [captionFontSize, setCaptionFontSize] = useState<CaptionFontSize>(
    getLocalStoragePlayerSettings()?.additionalConfig?.captionFontSize || CaptionFontSize.default
  );

  const [liveCaptionsOn, setLiveCaptionsOn] = useState<boolean>(
    getLocalStoragePlayerSettings()?.additionalConfig?.liveCaptionsOn || false
  );

  const { hbbtv } = useHbbtvConnect({
    setHbbtvStreamingActiveDevice,
    hbbtvStreamingActiveDevice,
    setMenuPopupVisible,
    cropStart,
    cropEnd,
    idec,
    encoder,
    indexId,
    bonusId,
  });

  const {
    activeChromecastDevice,
    chromecastState,
    getChromecastCurrentTime,
    getChromecastVolume,
    isChromecastApiAvailable,
    isChromecastSession,
    toggleChromecast,
    onChromecastChangeAudioTrack,
    onChromecastChangeSubtitles,
    onChromecastPlayPause,
    onChromecastSeek,
    onChromecastSeekBy,
    onChromecastVolumeChange,
    onChromecastMute,
    selectedChromecastAudioTrack,
    selectedChromecastSubtitles,
    setActiveIndexCheckCallback,
    setTimeUpdateCallback,
    setVolumeUpdateCallback,
  } = useChromecast({
    disabled: !!disableChromecast,
    mainContentId,
    playerClient,
    playerVariant,
    previewImage,
    streamUrl: currentStreamUrl,
    subtitles,
  });

  const timeshiftGapDuration = noGapTimeshift
    ? NO_GAP_LIVE_TIMESHIFT_BUFFER
    : TIMESHIFT_GAP_DURATION;
  const liveStreamDuration = noGapTimeshift ? NO_GAP_LIVE_TIMESHIFT_BUFFER : LIVE_STREAM_DURATION;

  useEffect(() => {
    setTimeShift(defaultTimeShift);
  }, [defaultTimeShift]);

  useEffect(() => {
    setEncoderTimeDiff(defaultEncoderDiff);
  }, [defaultEncoderDiff]);

  useEffect(() => {
    setIndexes(defaultIndexes);
  }, [defaultIndexes]);

  useEffect(() => {
    setAgeRestriction(defaultAgeRestriction);
  }, [defaultAgeRestriction]);

  useEffect(() => {
    setShowTitle(defaultShowTitle);
  }, [defaultShowTitle]);

  useEffect(() => {
    setVideoTitle(defaultVideoTitle);
  }, [defaultVideoTitle]);

  useEffect(() => {
    setVideoStartsAt(defaultVideoStartsAt);
  }, [defaultVideoStartsAt]);

  useEffect(() => {
    updateLocalStoragePlayerSettings({
      additionalConfig: {
        captionColorVariant: captionColorVariant,
        captionFontSize: captionFontSize,
        liveCaptionsOn: liveCaptionsOn,
      },
    });
  }, [captionColorVariant, captionFontSize, liveCaptionsOn]);

  useEffect(() => {
    const context = client.getContext();
    if (!context) {
      return;
    }

    client.setContext({
      ...context,
      ...(context.type === 'LIVE' ? { timeshift: timeShift === null ? 0 : timeShift } : {}),
    });
  }, [client, timeShift]);

  // sem se posílají spíše aplikační errory, které mají vyvolat UI response
  const setPlayerErrorCallback = useCallback(
    (error: any) => {
      // pokud error není, smažeme původní
      if (!error) {
        setPlayerError(null);
        return;
      }
      const newPlayerError = getPlayerError(error);

      if (error instanceof Error) {
        log.error({
          message: 'Player error.',
          error,
          // logData: { customData: { errorObject: error } },
        });
      } else {
        log.error({
          error,
          // logData: { customData: { errorObject: error } },
          message: 'Unknown error.',
        });
      }

      onError(newPlayerError);
      setPlayerError(newPlayerError);
    },
    [onError]
  );

  const contextValue = useMemo<PlayerContextValues>(
    () => ({
      activeChromecastDevice,
      ageRestriction,
      aspectRatio,
      bonusId,
      captionColorVariant,
      captionFontSize,
      chromecastState,
      cropEnd,
      cropStart,
      disableAirplay,
      disableChromecast,
      encoder,
      encoderTimeDiff,
      endTimestamp,
      externalId,
      forcedAudioOnly,
      getChromecastCurrentTime,
      getChromecastVolume,
      hbbtv,
      hbbtvStreamingActiveDevice,
      idec,
      indexId,
      indexListVisible,
      previewTrackStartOffset,
      indexes,
      isAudioOnly,
      isNewPlaylist,
      isChromecastApiAvailable,
      isChromecastSession,
      keyboardControlsHideState,
      licenseInfo,
      liveActivated,
      liveCaptionsOn,
      liveMode,
      liveStreamDuration,
      mediaMetaId,
      menuPopupVisible,
      noGapTimeshift,
      onChromecastChangeAudioTrack,
      onChromecastChangeSubtitles,
      onChromecastMute,
      onChromecastPlayPause,
      onChromecastSeek,
      onChromecastSeekBy,
      onChromecastVolumeChange,
      overlay,
      parentUrl,
      playbackRate,
      playerError,
      playersControllerContext,
      playlistOptions,
      preventHideControls,
      previewTrackBaseUrl,
      selectedChromecastAudioTrack,
      selectedChromecastSubtitles,
      setActiveIndexCheckCallback,
      setAgeRestriction,
      setAspectRatio,
      setCaptionColorVariant,
      setCaptionFontSize,
      setCurrentStreamUrl,
      setForcedAudioOnly,
      setHbbtvStreamingActiveDevice,
      setIndexListVisible,
      setIndexes,
      setKeyboardControlsHideState,
      setLiveActivated,
      setLiveCaptionsOn,
      setMenuPopupVisible,
      setOverlayIcon,
      setPlaybackRate,
      setPlayerError: setPlayerErrorCallback,
      setPreventHideControls,
      setShowRecommendedVideos,
      setShowTitle,
      setStartOffset,
      setTimeShift,
      setTimeUpdateCallback,
      setVideoStartsAt,
      setVideoTitle,
      setVolumeUpdateCallback,
      showId,
      showRecommendedVideos,
      showTitle,
      startOffset,
      startTimestamp,
      timeShift,
      timeshiftGapDuration,
      toggleChromecast,
      versionId,
      videoStartsAt,
      videoTitle,
    }),
    [
      activeChromecastDevice,
      ageRestriction,
      aspectRatio,
      bonusId,
      captionColorVariant,
      captionFontSize,
      chromecastState,
      cropEnd,
      cropStart,
      disableAirplay,
      disableChromecast,
      encoder,
      encoderTimeDiff,
      endTimestamp,
      externalId,
      forcedAudioOnly,
      getChromecastCurrentTime,
      getChromecastVolume,
      hbbtv,
      hbbtvStreamingActiveDevice,
      idec,
      indexId,
      indexListVisible,
      previewTrackStartOffset,
      indexes,
      isAudioOnly,
      isChromecastApiAvailable,
      isChromecastSession,
      isNewPlaylist,
      keyboardControlsHideState,
      licenseInfo,
      liveActivated,
      liveCaptionsOn,
      liveMode,
      liveStreamDuration,
      mediaMetaId,
      menuPopupVisible,
      noGapTimeshift,
      onChromecastChangeAudioTrack,
      onChromecastChangeSubtitles,
      onChromecastMute,
      onChromecastPlayPause,
      onChromecastSeek,
      onChromecastSeekBy,
      onChromecastVolumeChange,
      overlay,
      parentUrl,
      playbackRate,
      playerError,
      playersControllerContext,
      playlistOptions,
      preventHideControls,
      previewTrackBaseUrl,
      selectedChromecastAudioTrack,
      selectedChromecastSubtitles,
      setCaptionColorVariant,
      setCaptionFontSize,
      setForcedAudioOnly,
      setLiveCaptionsOn,
      setActiveIndexCheckCallback,
      setPlayerErrorCallback,
      setTimeUpdateCallback,
      setVolumeUpdateCallback,
      showId,
      showRecommendedVideos,
      showTitle,
      startOffset,
      startTimestamp,
      timeShift,
      timeshiftGapDuration,
      toggleChromecast,
      versionId,
      videoStartsAt,
      videoTitle,
    ]
  );

  // full error hlášku ukážeme vždy pokud k ní došlo před první inicializací
  // nebo pokud se z ní player už nevzpamatuje
  if (playerError && (!isRecoverableError(playerError) || !playerClient.initiated)) {
    return (
      <PlayerErrorOverlay
        // TODO: Chybí implementace kliků na tlačítka
        errorDescription={getErrorMessage(playerError)}
        onReloadButtonClick={reload}
      />
    );
  }

  return <PlayerContext.Provider value={contextValue}>{children}</PlayerContext.Provider>;
};

export default PlayerContextProvider;
