/* eslint-disable canonical/filename-no-index */
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { IconHelpCircle, IconRefresh } from '@czechtv/icons';
import { AspectRatioPlaceholder, Button } from '@czechtv/components';
import { AnalyticsContext } from '@czechtv/analytics-react';
import GradientOverlay from '../../../Player/Overlay/VOD/GradientOverlay/GradientOverlay';
import { useMediaBreakpoints } from '../../../utils/playerResponsive';
import PlayerBasedResponsive from '../../PlayerBasedResponsive/PlayerBasedResponsive';
import { BUTTON_SEPARATOR, FAQ_URL } from '../../../constants';
// import { useReportErrorUrl } from '../../../utils/supportUrls';
import { FaqLink } from '../../FaqLink/FaqLink';
import { formatMessage } from '../../../utils/formatMessage';
import { PlaylistErrorData } from '../../../utils/playlists/constants';
import { PlaylistOptions } from '../../../utils/playlists/constants';
import { overlayClassnames } from './Overlay.css';

export interface PlayerErrorMessage {
  subtitle?: string;
  subtitleShort?: string;
  title?: string;
}

const getShouldRenderErrorOverlayFooter = (
  showFooter: boolean,
  playlistErrorData?: PlaylistErrorData
) => {
  if (!showFooter) {
    return false;
  }
  if (playlistErrorData?.textShort || playlistErrorData?.textLong) {
    return false;
  }
  return true;
};

export interface PlayerErrorOverlayProps {
  baseUrl?: string;
  errorDescription?: PlayerErrorMessage;
  errorDetailMessage?: string;
  mainContentId?: string;
  onReloadButtonClick?: () => void;
  playlistErrorData?: PlaylistErrorData;
  playlistOptions?: PlaylistOptions;
  showFooter?: boolean;
  usingNewLivePlaylist?: boolean;
}

export const removeButtonSeparatorFromString = (
  raw?: string,
  buttonSeparator?: string
): string | undefined => {
  if (!raw) {
    return undefined;
  }
  const separator = buttonSeparator || '%';
  return raw.split(separator).join('');
};

const messages = {
  reloadButton: {
    id: 'PlayerErrorOverlay.reloadButton',
    defaultMessage: 'Znovu načíst',
    description: 'Tačítko pro pokus znovu načíst video',
  },
  reportErrorButton: {
    id: 'PlayerErrorOverlay.reportErrorButton',
    defaultMessage: 'Nahlásit chybu',
    description: 'Tačítko pro nahlášení chyby',
  },
  defaultErrorTitle: {
    id: 'PlayerErrorOverlay.defaultErrorTitle',
    defaultMessage: 'Omlouváme se, ale video se nepodařilo načíst',
    description: 'Defaultní název chybové hlášky',
  },
  desktopQuestion: {
    id: 'PlayerErrorOverlay.desktopVersionQuestion',
    defaultMessage: 'Máte stále problémy s přehráváním videa?',
    description: 'Hláška v případě větší velikosti než tablet',
  },
  tabletQuestion: {
    id: 'PlayerErrorOverlay.tabletVersionQuestion',
    defaultMessage: 'Máte stále problémy?',
    description: 'Zkrácená hláška na tabletu',
  },
  answer: {
    id: 'PlayerErrorOverlay.answer',
    defaultMessage: 'Podívejte se do ',
    description: 'Hláška v případě větší velikosti než tablet',
  },
  answer2: {
    id: 'PlayerErrorOverlay.answer2',
    defaultMessage: ', kde je možné i nahlásit chybu.',
    description: 'Hláška v případě větší velikosti než tablet',
  },
};

const PlayerErrorOverlay = ({
  onReloadButtonClick = () => {
    window.location.reload();
  },
  errorDescription,
  errorDetailMessage,
  playlistErrorData,
  showFooter = true,
  // baseUrl,
  mainContentId,
  usingNewLivePlaylist,
  playlistOptions,
}: PlayerErrorOverlayProps) => {
  const { isMaxMediumDesktop, isMaxTablet, isMaxMediumMobile } = useMediaBreakpoints();
  // const reportUrl = useReportErrorUrl({ baseUrl });
  const reportedRef = useRef<boolean>(false);
  // nepouzivat primo useAnalytics, aby si v iframu standalone error overlay nestezoval,
  // ze mu chybi provider
  const analytics = useContext(AnalyticsContext);

  const errDescription = useMemo(
    () =>
      errorDescription
        ? {
            title: removeButtonSeparatorFromString(errorDescription.title, BUTTON_SEPARATOR),
            subtitle: removeButtonSeparatorFromString(errorDescription.subtitle, BUTTON_SEPARATOR),
            subtitleShort: removeButtonSeparatorFromString(
              errorDescription.subtitleShort,
              BUTTON_SEPARATOR
            ),
          }
        : {
            title: formatMessage(messages.defaultErrorTitle),
          },
    [errorDescription]
  );

  useEffect(() => {
    if (!analytics || reportedRef.current) {
      return;
    }

    if (playlistErrorData) {
      analytics.trigger({
        type: 'PlayerPlaylistError',
        data: {
          mainContentId: playlistErrorData.videoId,
          error: playlistErrorData.errorStatusName,
        },
      });
      return;
    }
    // info budeme prilepovat kvuli analytikum primo do message
    const addLivePlaylistInfo = (message: string) => {
      if (message && usingNewLivePlaylist !== undefined) {
        return `${message}___USING_NEW_LIVE_PLAYLIST=${usingNewLivePlaylist}___`;
      }
      return message;
    };
    const error = errorDetailMessage || JSON.stringify(errDescription);
    analytics.trigger({
      type: 'PlayerError',
      data: {
        error: addLivePlaylistInfo(error),
        ...(mainContentId ? { mainContentId } : {}),
      },
    });
    reportedRef.current = true;
  }, [
    analytics,
    errDescription,
    errorDetailMessage,
    mainContentId,
    playlistErrorData,
    usingNewLivePlaylist,
  ]);

  useEffect(() => {
    return () => {
      reportedRef.current = false;
    };
  }, []);

  const shouldRenderErrorOverlayFooter = getShouldRenderErrorOverlayFooter(
    showFooter,
    playlistErrorData
  );

  const shouldRenderSeparator =
    !!errDescription.title &&
    ((!!errDescription.subtitle && !isMaxMediumMobile) ||
      (!!errDescription.subtitleShort && isMaxMediumMobile));

  return (
    <div className={overlayClassnames.overlayContainer} data-testid="PlayerError">
      <GradientOverlay className={overlayClassnames.overlay}>
        <div className={overlayClassnames.wrapper}>
          <div
            className={classNames(overlayClassnames.errorTitle, {
              separator: shouldRenderErrorOverlayFooter && shouldRenderSeparator,
            })}
          >
            {errDescription.title}
          </div>
          <div className={overlayClassnames.errorSubtitle}>
            {isMaxMediumMobile ? errDescription.subtitleShort : errDescription.subtitle}
          </div>
          {shouldRenderErrorOverlayFooter && (
            <div className={overlayClassnames.errorFooter}>
              <Button
                className={overlayClassnames.button}
                icon={isMaxMediumMobile ? undefined : IconRefresh}
                size={isMaxMediumDesktop ? 'medium' : 'large'}
                onClick={onReloadButtonClick}
              >
                {formatMessage(messages.reloadButton)}
              </Button>
              {isMaxMediumMobile ? (
                <>
                  <FaqLink
                    basicLinkClassName={overlayClassnames.helpIcon}
                    faqUrl={FAQ_URL}
                    playlistOptions={playlistOptions}
                  >
                    <IconHelpCircle className={overlayClassnames.icon} />
                  </FaqLink>
                </>
              ) : (
                <span>
                  {isMaxTablet
                    ? formatMessage(messages.tabletQuestion)
                    : formatMessage(messages.desktopQuestion)}{' '}
                  {isMaxTablet ? null : <br />}
                  {formatMessage(messages.answer)}
                  <FaqLink
                    basicLinkClassName={overlayClassnames.footerLink}
                    faqUrl={FAQ_URL}
                    playlistOptions={playlistOptions}
                  >
                    nápovědy
                  </FaqLink>
                  {formatMessage(messages.answer2)}
                </span>
              )}
            </div>
          )}
        </div>
      </GradientOverlay>
    </div>
  );
};

export default memo(PlayerErrorOverlay);

export const PlayerErrorOverlayStandalone = (props: PlayerErrorOverlayProps) => {
  return (
    <PlayerBasedResponsive>
      <AspectRatioPlaceholder>
        <PlayerErrorOverlay {...props} />
      </AspectRatioPlaceholder>
    </PlayerBasedResponsive>
  );
};
